<template>
  <div v-if="isLoaded" class="report">
    <div class="back-top" id="opp-back-top" @click="handleBack()">
      <div>
        <ion-icon name="arrow-back-outline" class="big-icon"></ion-icon>
      </div>
      <h2>Back</h2>
    </div>

    <hr class="good-line" />

    <div
      class="search-bar"
      @focusin="toggleIsSearching(true)"
      @focusout="toggleIsSearching(false)"
    >
      <div class="search-btn">
        <ion-icon name="search-outline" class="small-icon"></ion-icon>
      </div>

      <input
        type="text"
        name="search"
        placeholder="Type the name the person who you want to report..."
        v-model="search"
      />
    </div>

    <ul
      class="search-results"
      v-show="isSearching && search != '' && searchAllProfile.length > 0"
    >
      <ContactCard
        v-for="(person, index) in searchAllProfile"
        :class="{ 'first-item': index == 0 && false }"
        :key="person.id"
        :contact="{ ...person, chatterName: person.name }"
        @click="handleSelect({ ...person, chatterName: person.name })"
      />
    </ul>

    <div class="report-dashboard">
      <div class="report-person">
        <h2 v-if="reportTarget">Your Report Target</h2>
        <ContactCard
          v-if="reportTarget"
          class="first-item"
          :contact="reportTarget"
        />
        <h2 v-else style="text-align: center;margin: 4vh 0;">
          You have not yet selected the person to report.
        </h2>
      </div>
      <hr v-if="reportTarget" />
      <div v-if="reportTarget" class="report-category">
        <h2>Your Report Category</h2>
        <select id="category" v-model="category">
          <option value="default">Please Select</option>
          <option value="fake">Fake Opportunity</option>
          <option value="irresponsible">
            Irresponsible job poster (e.g. extremely unresponsive, withdrew
            before deadline, etc.)
          </option>
          <option value="inappropriate uploads">
            Inappropriate Profile Picture or Resume
          </option>
          <option value="verbal abuse">Verbal Abuse, Discrimination</option>
          <option value="Others">Others</option>
        </select>
      </div>
      <hr v-if="reportTarget" />
      <div v-if="reportTarget" class="report-detail">
        <h2>Detail</h2>
        <textarea
          class="detail-box"
          placeholder="Type your report details here ..."
          name="message-input-field"
          wrap="hard"
          v-model="reportDetail"
          @keyup.enter.exact="handleSend"
          @keydown.enter.shift.exact="newline"
        ></textarea>
      </div>
    </div>
    <div class="report-block">
      <div class="empty-block"></div>
      <button
        :class="{
          'report-btn': true,
          'confirm-report': confirmReport,
        }"
        @click="
          if (confirmReport) {
            handleReport();
          }
          confirmReport = !confirmReport;
        "
      >
        <p v-if="confirmReport">Confirm</p>
        Report
      </button>
    </div>
  </div>
</template>

<script>
import getUser from "@/composables/getUser";
import getAllProfile from "@/composables/Profile/getAllProfile";
import ContactCard from "@/components/Messages/contactCard.vue";
import sendReport from "@/composables/AccountSettings/sendReport";
import { ref, watchEffect, onMounted } from "vue";
import { useRouter } from "vue-router";
export default {
  components: {
    ContactCard,
  },
  async setup() {
    const { user } = getUser();
    const router = useRouter();
    const isSearching = ref(false);
    const allProfile = ref(null);
    const searchAllProfile = ref(null);
    const search = ref("");
    const noSpecial = ref(null);
    noSpecial.value = /[$&+,:;=?[\]@#|{}'<>.^*()%!-/]/;
    const category = ref("default");
    const reportTarget = ref(null);
    const confirmReport = ref(false);
    const reportDetail = ref(null);
    const isLoaded = ref(false);

    onMounted(async () => {
      await getAllProfile(allProfile);
      isLoaded.value = true

      watchEffect(() => {
        searchAllProfile.value = allProfile.value;

        searchAllProfile.value = searchAllProfile.value.filter((person) => {
          if (search.value == null) {
            return false;
          } else if (noSpecial.value.test(search.value)) {
            alert("no specials");
            search.value = "";
            return false;
          }
          return person.name.toLowerCase().match(search.value.toLowerCase());
        });

        searchAllProfile.value = searchAllProfile.value.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
      });
    })

    const handleBack = () => {
      router.push({ name: "Senior-AccountSettings" });
    };

    const toggleIsSearching = (status) => {
      if (status) {
        isSearching.value = true;
      } else {
        setTimeout(() => {
          isSearching.value = false;
        }, 500);
      }
    };

    const handleSelect = (info) => {
      reportTarget.value = info;
    };

    const handleReport = async () => {
      if(!reportTarget.value){
        alert("Please search and select a person to report.")
      }
      else if(category.value == "default"){
        // console.log(reportTarget.value)
        alert("Please choose a report category.")
      }
      else{
        await sendReport(
          reportTarget.value.id,
          category.value,
          reportDetail.value,
          user.value.uid
        );
        alert("Report Sent! Thank you for your report.")
        router.push({ name: "Senior-AccountSettings" });

      }
    };

    return {
      search,
      isSearching,
      category,
      reportTarget,
      reportDetail,
      confirmReport,
      searchAllProfile,
      handleBack,
      toggleIsSearching,
      handleSelect,
      handleReport,
      isLoaded,
    };
  },
};
</script>

<style scoped>
.report {
  flex: 1 0 80%;
  padding: 3vh 8vh;
  height: 100vh;
  animation: fadein 1s;
  position: relative;
}

@media screen and (max-width: 630px) {
  .report {
    padding: 3vh 1vh;
  }
}

.back {
  width: 100%;
  margin-bottom: 3vh;
}

.first-item {
  cursor: auto;
  border-top: none;
  font-size: 150%;
}

.first-item:hover {
  background-color: white;
}

.search-bar {
  margin: 1vh 0;
  background-color: #f1f1f1;
  display: flex;
  align-items: center;
  border-radius: 10px;
}

.search-bar div {
  flex: 0 1 10%;
  color: #808080;
  font-size: 80%;
  padding-left: 0.5vw;
  padding-right: 0.5vw;
}

.search-bar input,
.search-bar input:focus {
  display: block;
  flex: 0 0 80%;
  color: #808080;
  height: 6vh;
  background-color: #f1f1f1;
  border: none;
  outline: none;
}

.search-results {
  position: absolute;
  top: 18vh;
  left: 8vw;
  padding: 0 0;
  border-radius: 10px;
  max-height: 50vh;
  width: 81.5%;
  overflow-y: scroll;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.search-results::-webkit-scrollbar {
  display: none;
}

.search-button {
  background-color: #cca2d8;
  text-align: center;
  border-radius: 10px;
  width: 100%;
  height: 3vh;
  color: white;
  cursor: pointer;
}

#category {
  display: block;
  width: 50%;
  font-size: 120%;
  margin: 1vh 0;
  margin-bottom: 2vh;
  padding: 0 1vw;
  outline: none;
  border: 2px #cca2d8 solid;
  border-radius: 20px;
  height: 35px;
}

.report-dashboard {
  margin-top: 5vh;
  border: 2px red solid;
  border-radius: 20px;
  padding: 4vh;
}

.report-person h2 {
  margin-bottom: 1vh;
}

.report-category {
  margin-top: 3vh;
}

.report-category h2 {
  margin-bottom: 2vh;
}

.report-detail {
  margin-top: 3vh;
}

.detail-box,
.detail-box:focus {
  background-color: white;
  border: none;
  font-size: 100%;
  padding: 1vh;
  margin-top: 1vh;
  height: 5vh;
  width: 100%;
  resize: none;
}

.report-block {
  display: flex;
  margin-top: 3vh;
}

.empty-block {
  flex: 1 1 auto;
}

.report-btn {
  flex: 0 0 20%;
  font-size: 120%;
  font-weight: bold;
  height: 6vh;
  min-width: 100px;
  margin-left: auto;
  border-color: rgb(252, 91, 91);
  color: rgb(252, 91, 91);
  background-color: white;
  border-radius: 5vh;
}

.report-btn:hover {
  border-color: red;
  color: red;
  cursor: pointer;
}

.report-btn p {
  display: inline;
}

.confirm-report {
  flex: 0 0 200px;
  color: white;
  border-color: rgb(252, 91, 91);
  background-color: rgb(252, 91, 91);
}

.confirm-report:hover {
  color: white;
  border-color: red;
  background-color: red;
}
</style>
