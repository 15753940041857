import { db } from '@/firebase/config'
import firebase from "firebase/app";
import "firebase/firestore";

const sendReport = async (reportedUid, category, detail, reporterUid) => {
  try {
    const now = new firebase.firestore.Timestamp.now().toDate();

    await db.collection("Reports").add({
      reportedUid: reportedUid,
      category: category,
      detail: detail,
      reporterUid: reporterUid,
      time: now
    })
    // console.log("sendReport activated");
  } catch (error) {
    alert("SendReport failed: " + error);
  }

};

export default sendReport;
